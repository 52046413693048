








import { Component, Prop, Vue } from "vue-property-decorator";

@Component( {
  name: "AvDivider",
} )
export default class AvDivider extends Vue {
  @Prop( { required: false, default: () => "1px" } ) size!: string;
  @Prop( { required: false, default: () => "lightgrey" } ) color!: string;

  get checkSlotDefault() {
    const keys = Object.keys(this.$slots);

    return keys && keys[ 0 ] == "default";
  }
}
