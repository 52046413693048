



















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import CollapseHorizontalCard from "@/views/administrative/settings/events/components/CollapseHorizontalCard.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import { IEventoPerfil } from "../interfaces/IEventsProfile";
import { Ref } from "@vue/composition-api";
import { IEventByProfile } from "@core/services/interfaces/administrator/IAdministratorService";

@Component({
  name: "CollapseHorizontal",
  components: {
    AppCollapse,
    CollapseHorizontalCard,
  },
})
export default class CollapseHorizontal extends Vue {
  @Prop() items!: Ref<IEventoPerfil[]>;

  changeEvent(event: IEventByProfile): void {
    let itemIdenx = this.items.value.findIndex((item) => {
      const eventExist = item.eventos.findIndex(
        (e) => e.eventoId == event.eventoId
      );

      if (eventExist >= 0) return true;
    });

    let eventIndex = -1;
    if (itemIdenx >= 0) {
      eventIndex = this.items.value[itemIdenx].eventos.findIndex(
        (e) => e.eventoId == event.eventoId
      );
    }

    if (itemIdenx >= 0 && eventIndex >= 0)
      this.items.value[itemIdenx].eventos[eventIndex].possuiEvento =
        event.possuiEvento;
  }
}
