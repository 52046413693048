var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email-app-details"},[_c('div',{staticClass:"email-detail-header"},[_c('div',{staticClass:"email-header-left d-flex align-items-center"},[_c('span',{staticClass:"go-back mr-1"},[_c('feather-icon',{staticClass:"align-bottom",attrs:{"icon":_vm.$store.state.appConfig.isRTL
              ? 'ChevronRightIcon'
              : 'ChevronLeftIcon',"size":"20"},on:{"click":function($event){return _vm.$emit('close-profile-view')}}})],1),_c('h4',{staticClass:"email-subject mb-0"},[_vm._v(" "+_vm._s(_vm.profileViewData.nome)+" ")])]),_c('div',{staticClass:"email-header-right ml-2 pl-1"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"SaveIcon","size":"17"},on:{"click":_vm.updateProfile}}),_c('feather-icon',{staticClass:"ml-75 cursor-pointer",class:{
          'text-muted pointer-events-none':
            !_vm.opendedProfileMeta.hasPreviousProfile,
        },attrs:{"icon":_vm.$store.state.appConfig.isRTL
            ? 'ChevronRightIcon'
            : 'ChevronLeftIcon',"size":"17"},on:{"click":function($event){return _vm.$emit('change-opened-profile', 'previous')}}}),_c('feather-icon',{staticClass:"ml-75 cursor-pointer",class:{
          'text-muted pointer-events-none':
            !_vm.opendedProfileMeta.hasNextProfile,
        },attrs:{"icon":_vm.$store.state.appConfig.isRTL
            ? 'ChevronLeftIcon'
            : 'ChevronRightIcon',"size":"17"},on:{"click":function($event){return _vm.$emit('change-opened-profile', 'next')}}})],1)]),_c('vue-perfect-scrollbar',{staticClass:"email-scroll-area scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"email-label"},[_c('av-switch',{staticClass:"d-inline",model:{value:(_vm.profileViewData.status),callback:function ($$v) {_vm.$set(_vm.profileViewData, "status", $$v)},expression:"profileViewData.status"}}),_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":("light-" + (_vm.resolveStatusVariant(_vm.profileViewData.status)))}},[_vm._v(" "+_vm._s(_vm.resolveStatusText(_vm.profileViewData.status))+" ")]),_c('span',{staticClass:"text-small"},[_vm._v(_vm._s(_vm.profileViewData.descricao && _vm.profileViewData.descricao.trim() != "" ? " - " + _vm.profileViewData.descricao : ""))])],1)])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('collapse-horizontal',{attrs:{"items":_vm.itemsCollapse}})],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }