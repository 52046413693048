










import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import AvCheckbox from "@/components/av-checkbox/AvCheckbox.vue";
import AvDivider from "@/components/av-divider/AvDivider.vue";
import { IEventByProfile } from "@core/services/interfaces/administrator/IAdministratorService";

@Component({
  name: "EventsHorizontalCard",
  components: {
    AvCheckbox,
    AvDivider,
  },
})
export default class EventsHorizontalCard extends Vue {
  @Prop() item!: IEventByProfile;
  @Prop() checkedPerfis!: boolean;

  checkEvento: boolean = false;

  @Watch("checkedPerfis")
  onCheckedPerfisChange(newValue: boolean) {
    this.checkEvento = newValue;
  }

  @Watch("checkEvento")
  onCheckEventoChange(newValue: boolean) {
    this.$emit("changeIdDosEventosAtivos", {
      ...this.item,
      possuiEvento: newValue,
    });
  }

  mounted() {
    this.checkEvento = this.item.possuiEvento;
  }

  updated() {
    this.checkEvento = this.item.possuiEvento;
  }
}
