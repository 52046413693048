













































































































import { BRow, BCol, BCard, BLink, BBadge } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import CollapseHorizontal from "./components/CollapseHorizontal.vue";
import { AvSwitch } from "@/components";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ENUMS from "@/enums";

// Services
import { useAdministrator } from "@/services";

// Interfaces
import { ref, Ref } from "@vue/composition-api";
import {
  IEventByProfile,
  IProfile,
  IUpdateProfile,
} from "@core/services/interfaces/administrator/IAdministratorService";
import { IEventoPerfil } from "./interfaces/IEventsProfile";

@Component({
  name: "ProfileView",
  components: {
    // BSV
    BRow,
    BCol,
    BCard,
    BLink,
    BBadge,
    AvSwitch,

    // 3rd Party
    VuePerfectScrollbar,

    CollapseHorizontal,
  },
})
export default class ProfileView extends Vue {
  @Prop({ required: true }) profileViewData!: IProfile;
  @Prop({ required: true }) opendedProfileMeta!: {
    hasNextProfile: boolean;
    hasPreviousProfile: boolean;
  };

  // Data
  $ENUMS = ENUMS;
  itemsCollapse: Ref<IEventoPerfil[]> = ref([]); // Lista de eventos do perfil, Móduto { eventos: Array }
  eventsUsersIds: string[] = []; // Ids de eventos ativos no perfil
  perfectScrollbarSettings = {
    maxScrollbarLength: 150,
  };

  // Computeds
  get profileId(): string {
    return this.profileViewData.id;
  }

  // Watchs
  @Watch("profileId")
  fetchListEventsByProfile() {
    useAdministrator
      .requestListEventsByProfile(this.profileViewData.id)
      .then((response) => {
        this.filterProfile(response.data.data);
      });
  }

  // Functions
  /**
   * UTIL: essa função mapeia uma variável booleana para as classes correspondentes.
   */
  resolveStatusVariant = (status: boolean): string => {
    if (status) return "success";
    if (!status) return "danger";
    return "primary";
  };

  resolveStatusText = (status: boolean): string => {
    return status ? this.$ENUMS.TEXT.ATIVO : this.$ENUMS.TEXT.INATIVO;
  };

  /**
   * Mapea os eventos recebidos por módulos.
   * Móduto { eventos: Array }
   */
  filterProfile(data: IEventByProfile[]): void {
    let eventsByProfile: any = {};
    let activeEventsIds: string[] = [];

    data.forEach((event: IEventByProfile) => {
      let eventNameKey: string = event.nomeDoModulo;

      if (event.possuiEvento) {
        activeEventsIds.push(event.eventoId);
      }

      if (typeof eventsByProfile[eventNameKey] == "undefined")
        eventsByProfile[eventNameKey] = [];
      if (eventsByProfile[eventNameKey]) {
        eventsByProfile[eventNameKey].push(event);
      }
    });
    this.eventsUsersIds = activeEventsIds;

    let filterEvents: IEventoPerfil[] = Object.keys(eventsByProfile).map(
      (key: string) => {
        let countActiveEvents = 0;
        eventsByProfile[key].forEach((event: IEventByProfile) => {
          if (event.possuiEvento) {
            countActiveEvents++;
          }
        });

        return {
          nomeDoModulo: key,
          eventos: eventsByProfile[key],
          checkFull: eventsByProfile[key].length === countActiveEvents,
          countEventosAtivos: countActiveEvents,
        };
      }
    );

    this.itemsCollapse.value = filterEvents;
  }

  async updateProfile() {
    const activeEvents = this.itemsCollapse.value
      .reduce((acc, current) => {
        return [...acc, ...current.eventos];
      }, [] as IEventByProfile[])
      .filter((e) => e.possuiEvento);

    const profile: IUpdateProfile = {
      id: this.profileViewData.id,
      sistemaId: "54f1313e-afa1-4810-9e0c-31c574b3e484",
      nome: this.profileViewData.nome,
      descricao: this.profileViewData.descricao,
      status: this.profileViewData.status,
      eventoIds: activeEvents.map((e) => e.eventoId),
    };

    useAdministrator
      .requestUpdateProfile(profile)
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Perfil atualizado com sucesso!",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao atualizar perfil",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }
}
